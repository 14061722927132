import { ScrollPanel } from 'primereact/scrollpanel';
import { Tag } from 'primereact/tag';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShoppingCart } from '../../context/ShoppingCartContext';
import FilterType from '../../models/FilterType';
import PictureProduct from '../../models/PictureProduct';
import ImageModal from '../ImageModal/ImageModal';
import Product from '../../models/Product';
import './ProductTableList.css';
import { AuthService } from '../../services/auth.service';

interface Props {
  filters: FilterType;
  products: Product[];
  profilePictures: PictureProduct[];
}

export const ProductTableList: FC<Props> = ({
  products,
  //stocks,
  profilePictures,
}) => {
  const { increaseCartQuantity } = useShoppingCart();
  const { t } = useTranslation();
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [imageForModal, setImageForModal] = useState('');
  const role = AuthService.GetRole();

  const save = (
    code: string,
    nameProduct: string,
    price: number | null | undefined,
    imgUrl: string,
    lowest: number,
    multiple: number,
  ) => {
    increaseCartQuantity(
      code,
      nameProduct,
      price ?? 0,
      imgUrl,
      lowest,
      multiple,
    );
  };

  const handleImageClick = (imgUrl: string) => {
    setIsImageModalOpen(true);
    setImageForModal(imgUrl);
  };

  const handleCloseImageModal = () => {
    setIsImageModalOpen(false);
    setImageForModal('');
  };

  return (
    <div style={{ paddingLeft: '5%' }}>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 50px)' }}>
        <table className="table-products">
          <tbody>
            {products.map((product) => (
              <tr key={product.code}>
                <td>
                  <div
                    className="tooltip"
                    onClick={() =>
                      handleImageClick(
                        profilePictures.find(
                          (profilePicture) =>
                            profilePicture.idProduct === product.code,
                        )?.urlProfilePicture ??
                          'https://res.cloudinary.com/dkfvgi7bh/image/upload/v1717055104/nfgnbce4ub9k6tftpyfy.jpg',
                      )
                    }
                  >
                    <img
                      style={{
                        height: '50px',
                        width: 'auto',
                        cursor: 'pointer',
                      }}
                      src={
                        profilePictures.find(
                          (profilePicture) =>
                            profilePicture.idProduct === product.code,
                        )?.urlProfilePicture ??
                        'https://res.cloudinary.com/dkfvgi7bh/image/upload/v1717055104/nfgnbce4ub9k6tftpyfy.jpg'
                      }
                      alt={product.nameProduct}
                      onError={(e) => {
                        const target = e.target as HTMLImageElement; // Type assertion
                        target.src =
                          'https://res.cloudinary.com/dkfvgi7bh/image/upload/v1717055104/nfgnbce4ub9k6tftpyfy.jpg';
                      }}
                    />
                  </div>
                </td>
                <td>{product.code}</td>
                <td>
                  <strong>{product.nameProduct.toLocaleLowerCase()}</strong>
                </td>
                <td>
                  <div className="tags-container">
                    {product.availableWW > 0 ? (
                      <Tag
                        className="bg-green-100"
                        icon="pi pi-check"
                        severity="warning"
                        value={t('card_component.tag.available')}
                      ></Tag>
                    ) : (
                      <Tag
                        className="bg-red-100"
                        icon="pi pi-times"
                        severity="warning"
                        value={t('card_component.tag.unavailable')}
                      ></Tag>
                    )}
                    {product.isOnDemand ? (
                      <Tag
                        className="bg-orange-100"
                        icon="pi pi-exclamation-circle"
                        severity="warning"
                        data-pr-tooltip={t('card_component.tag.on_demand')}
                        value={t('card_component.tag.on_demand')}
                      ></Tag>
                    ) : null}
                    {product.isEnabled === 'Agotar' ? (
                      <Tag
                        className="bg-blue-100"
                        icon="pi pi-tag"
                        severity="warning"
                        value={t('card_component.tag.end_stock')}
                      ></Tag>
                    ) : null}
                  </div>
                </td>
                <td>
                  <strong>
                    {role === 'No Price'
                      ? ''
                      : `${(product.price ?? 0).toFixed(2)} €`}
                  </strong>
                </td>
                <td>
                  <button
                    className="meso-button"
                    onClick={() =>
                      save(
                        product.code,
                        product.nameProduct,
                        product.price,
                        profilePictures.find(
                          (profilePicture) =>
                            profilePicture.idProduct === product.code,
                        )?.urlProfilePicture ?? '/images/no-image.jpg',
                        product.lowest ?? 1,
                        product.multiple ?? 1,
                      )
                    }
                  >
                    {t('card_component.add_to_cart_btn')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ScrollPanel>
      {isImageModalOpen && (
        <ImageModal imgUrl={imageForModal} onClose={handleCloseImageModal} />
      )}
    </div>
  );
};
